












































































































































import { Vue, Component } from 'vue-property-decorator'
import { ContractSymbol, OptionMsgContent, OtmRange, PriceCls, PriceMsgContent } from '@/services/data'
import ws, { SocketMsg } from '@/services/socket'
import { setPriceValues } from '@/services/func'
import WatchListService from '@/services/watchListService'
import NumberRowEditor from '@/components/NumberRowEditor.vue'
import NumberRowSelector from '@/components/NumberRowSelector.vue'
import freebieService, { StockFreebie } from '@/services/freebieService'
import settingService, { SettingValueModel } from '@/services/settingService'
import SymbolName from '@/components/SymbolName.vue'
import validation, { Ruler } from '@/common/element-val'

interface FreebieSetting {
  PremiumPercentile: number;
  MinimumPremium: number;
  MinimumPremiumPercent: number;
}

@Component({
  components: {
    NumberRowEditor,
    NumberRowSelector,
    SymbolName
  }
})
export default class FreebieDetector extends Vue {
  $refs: {
    freebieLowEditor: NumberRowEditor;
    freebieHighEditor: NumberRowEditor;
    downloadLowEditor: NumberRowEditor;
    downloadHighEditor: NumberRowEditor;
  }

  isLoading = false
  FreebieSetting: FreebieSetting = null
  Items: StockFreebie[] = []
  CurrentSymbol: ContractSymbol
  RangeDialogVisible = false
  EditingOtmRange: {SymbolId: number; Download: OtmRange;Freebie: OtmRange } = {
    SymbolId: null,
    Download: {
      SymbolId: null,
      Low: null,
      High: null,
      Run: null,
      RangeType: 'Download'
    },
    Freebie: {
      SymbolId: null,
      Low: null,
      High: null,
      Run: null,
      RangeType: 'Freebie'
    }
  }

  freebieRangeRuler = [{
    validator: validation.required(),
    trigger: 'trigger'
  }, {
    validator: validation.fun()(() => {
      if (this.EditingOtmRange == null) return true
      if (this.EditingOtmRange.Freebie.Low == null || this.EditingOtmRange.Freebie.High == null) return true
      else return this.EditingOtmRange.Freebie.Low < this.EditingOtmRange.Freebie.High && (this.EditingOtmRange.Freebie.High - this.EditingOtmRange.Freebie.Low) >= 1
    }, 'Low must be at least 1% lower than High'),
    trigger: 'blur'
  }]

  downloadRangeRuler = [{
    validator: validation.required(),
    trigger: 'trigger'
  }, {
    validator: validation.fun()(() => {
      if (this.EditingOtmRange == null) return true
      if (this.EditingOtmRange.Download.Low == null || this.EditingOtmRange.Download.High == null) return true
      else return this.EditingOtmRange.Download.Low < this.EditingOtmRange.Download.High && (this.EditingOtmRange.Download.High - this.EditingOtmRange.Download.Low) >= 1
    }, 'Low must be at least 1% lower than High'),
    trigger: 'blur'
  }]

  SettingDialogVisible = false
  EditingSetting: SettingValueModel = {
    Name: null,
    Value: null,
    ValueType: 'Number'
  }

  EditingRuler: Ruler = []

  $num (num: number): string {
    if (num != null && num !== undefined) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }
    return null
  }

  async mounted () {
    ws.Server.init()
    ws.Server.addMessageCallBack(this.msgCallBack)
    await this.getWatchListAsync()
    const ranges = await freebieService.getOtmRanges()
    if (ranges.Result && ranges.Result.length > 0) {
      ranges.Result.forEach(p => {
        const item = this.Items.find(q => q.StockPrice.Symbol.SymbolId === p.SymbolId)
        if (item) {
          if (p.RangeType === 'Download') item.DownloadRange = p
          if (p.RangeType === 'Freebie') item.FreebieRange = p
        }
      })
    }
    await this.getOptions()
  }

  async getOptions () {
    this.isLoading = true
    const res = await freebieService.getDetectedFreebies()
    if (res.Result) {
      this.FreebieSetting = {
        PremiumPercentile: res.Result.Percentile,
        MinimumPremium: res.Result.MinPremiumValue,
        MinimumPremiumPercent: res.Result.MinPremiumPercent
      }
      res.Result.SymbolOpts.forEach(p => {
        if (p.Range) {
          p.Options = p.Options.filter(q => q.Otm >= p.Range.Low && q.Otm <= p.Range.High)
        }
      })
      for (const item of this.Items) {
        const find = res.Result.SymbolOpts.find(p => p.SymbolId === item.StockPrice.Symbol.SymbolId)
        if (find) {
          item.Gmt = find.Gmt
          item.PutOptions = find.Options.filter(q => q.CallPut === 'P').sort((a, b) => {
            return a.Strike - b.Strike
          })
          item.CallOptions = find.Options.filter(q => q.CallPut === 'C').sort((a, b) => {
            return b.Strike - a.Strike
          })
        }
      }
    }
    this.sendContext()
    this.isLoading = false
  }

  async showRangeDialog (item: StockFreebie) {
    const ranges = await freebieService.getOtmRanges(item.StockPrice.Symbol.SymbolId)
    if (ranges.Result && ranges.Result.length > 0) {
      if (ranges.Result[0].RangeType === 'Download') item.DownloadRange = ranges.Result[0]
      if (ranges.Result[0].RangeType === 'Freebie') item.FreebieRange = ranges.Result[0]
    }
    this.CurrentSymbol = item.StockPrice.Symbol
    this.RangeDialogVisible = true
    this.EditingOtmRange.SymbolId = item.StockPrice.Symbol.SymbolId
    if (item.DownloadRange) { this.EditingOtmRange.Download = item.DownloadRange }
    if (item.FreebieRange) { this.EditingOtmRange.Freebie = item.FreebieRange }
  }

  async showEditing (name: string) {
    this.EditingSetting.Value = null
    this.EditingSetting.Name = name
    if (name === 'PremiumPercentile' || name === 'MinimumPremiumPercent') {
      this.EditingRuler = validation.requiredIntRule()
    } else if (name === 'MinimumPremium') {
      this.EditingRuler = validation.requiredNumberRule()
    }
    this.SettingDialogVisible = true
    this.EditingSetting.Value = await this.getSettingsAsync(name)
  }

  async saveOtmRange () {
    const valid: boolean = await this.$refs.freebieLowEditor.validate() &&
      await this.$refs.freebieHighEditor.validate() &&
      await this.$refs.downloadLowEditor.validate() &&
      await this.$refs.downloadHighEditor.validate()
    if (!valid) {
      return
    }
    this.EditingOtmRange.Freebie.SymbolId = this.EditingOtmRange.SymbolId
    this.EditingOtmRange.Download.SymbolId = this.EditingOtmRange.SymbolId
    const res1 = await freebieService.updateRange(this.EditingOtmRange.Download)
    const res2 = await freebieService.updateRange(this.EditingOtmRange.Freebie)
    if (res1.Error || res2.Error) {
      await this.$alert(res1.Error || res2.Error)
    } else {
      const item = this.Items.find(p => p.StockPrice.Symbol.SymbolId === this.EditingOtmRange.SymbolId)
      if (item.DownloadRange == null) {
        item.DownloadRange = { SymbolId: this.EditingOtmRange.SymbolId, Low: null, High: null, Run: false }
      } else item.DownloadRange = this.EditingOtmRange.Download
      if (item.FreebieRange == null) {
        item.FreebieRange = { SymbolId: this.EditingOtmRange.SymbolId, Low: null, High: null, Run: false }
      } else item.FreebieRange = this.EditingOtmRange.Freebie
      this.RangeDialogVisible = false
      this.CurrentSymbol = null
    }
    await this.getOptions()
  }

  async saveUserSetting () {
    this.EditingSetting.Value = this.EditingSetting.Value.toString()
    const res = await settingService.addOrUpdateUserSettingAsync([this.EditingSetting])
    if (res.Error) {
      await this.$alert(res.Error)
    } else {
      this.SettingDialogVisible = false
      await this.getOptions()
    }
  }

  async getSettingsAsync (name: string) {
    const res = await settingService.readUserSettingsByNamesAsync([name])
    for (const item of res.Result) {
      if (item.Name === name) {
        return item.Value
      }
    }
  }

  async getWatchListAsync () {
    const res = await WatchListService.getWatchListsAsync(null, 'Core Portfolio')
    if (res.Error) {
      await this.$alert(res.Error)
      return
    }
    for (const group of res.Result) {
      group.Symbols.forEach(p => {
        if (p.Price == null) {
          p.Price = new PriceCls({ Open: null, Close: null, Last: null })
        }
      })
    }
    if (res.Result.length > 0) {
      this.Items = res.Result[0].Symbols.map(p => {
        return {
          StockPrice: p,
          DownloadRange: null,
          FreebieRange: null,
          CallOptions: [],
          PutOptions: []
        }
      })
    }
  }

  sendContext () {
    const optionId = new Array<number>()
    for (const item of this.Items) {
      for (const cp of item.CallOptions) {
        optionId.push(cp.OptionId)
      }
      for (const po of item.PutOptions) {
        optionId.push(po.OptionId)
      }
    }
    ws.Server.sendContext({
      page: 'freebielist',
      symbols: this.Items.map(p => p.StockPrice.Symbol.SymbolId),
      options: optionId
    })
  }

  destroyed (): void {
    ws.Server.removeMessageCallBack(this.msgCallBack)
  }

  getOtm (right: string, strike: number, stockPrice: number) {
    return right === 'C'
      ? Math.floor(((strike / stockPrice) - 1) * 100)
      : Math.floor((1 - (strike / stockPrice)) * 100)
  }

  async msgCallBack (msg: SocketMsg) {
    if (msg.Action === 'resNot' && msg.Content === 'freebie') {
      await this.getOptions()
      return
    }
    if (msg.Action === 'resStk') {
      for (const sp of this.Items) {
        if (sp.StockPrice.Price == null) {
          sp.StockPrice.Price = new PriceCls({ Open: null, Close: null, Last: null })
        }
        if (sp.StockPrice.Symbol.SymbolUId === msg.MsgId) {
          const content = msg.Content as PriceMsgContent
          setPriceValues(content, sp.StockPrice.Price)
          for (const item of sp.CallOptions) {
            item.StockPrice = sp.StockPrice.Price.Last
            item.Otm = this.getOtm(item.CallPut, item.Strike, item.StockPrice)
          }
          for (const item of sp.PutOptions) {
            item.StockPrice = sp.StockPrice.Price.Last
            item.Otm = this.getOtm(item.CallPut, item.Strike, item.StockPrice)
          }
        }
      }
    }
    if (msg.Action === 'resOpt') {
      const content = msg.Content as OptionMsgContent
      for (const sp of this.Items) {
        const findC = sp.CallOptions?.find(p => p.Uid === msg.MsgId)
        const findP = sp.PutOptions?.find(p => p.Uid === msg.MsgId)
        if (content.Name === 'OptPrice') {
          if (findC != null) {
            findC.OptionPrice = content.Value
          }
          if (findP != null) {
            findP.OptionPrice = content.Value
          }
        }
      }
    }
  }
}
